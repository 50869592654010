<template>
  <v-dialog v-model="isOpen" max-width="400px" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title v-bind="attrs" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            Создать новую группу
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Новая группа</span>
      </v-card-title>
      <v-card-text>
        <v-text-field placeholder="Введите название" required outlined hide-details v-model="name"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.prevent="close">Отмена</v-btn>
        <v-btn color="hemp" text :disabled="!name" @click.prevent="addField">Создать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DialogAddGroup',
  data() {
    return {
      isOpen: false,
      name: '',
    };
  },
  methods: {
    ...mapActions(['CREATE_GROUP']),
    addField() {
      this.CREATE_GROUP(this.name)
        .then(createdGroup => {
          this.$notify({ text: `Группа "${createdGroup.name}" успешно добавлена`, type: 'success' });

          this.$emit('create', createdGroup);

          this.close();
        })
        .catch(() => { this.$notify({ text: 'Группа не создана', type: 'error' }); });
    },
    close() {
      this.isOpen = false;
      this.name = '';
    },
  },
};
</script>
