<template>
  <v-col cols="12">
    <v-select
      :items="targets"
      v-model="model"
      outlined
      hide-details
      dense
      label="Выберите объект ВАТС"
    />
  </v-col>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Ivr',
  props: ['value'],
  computed: {
    ...mapState({
      ivrTargets: state => state.ivrSettings.ivrTargets,
    }),
    targets() {
      return this.ivrTargets.reduce((total, group) => ([
        ...total,
        { header: group.name },
        ...group.list.map(({ name, number }) => ({ value: +number, text: `${number} - ${name}` })),
      ]), []);
    },
    model: {
      get() {
        return this.value;
      },
      set(num) {
        this.$emit('input', num);
      },
    },
  },
};
</script>
