export default {
  autocall: {
    name: '',
    comment: '',
    group: 'Default',
    number: null,
    dateStart: '',
    speed: 160,
    email: null,
    isEnableEmailReport: false,
  },
  schedule: {
    autoRestart: null,
    exceptions: '',
    // expirationDatetime: '',
    tz: Math.round((new Date().getTimezoneOffset() / 60) * -1),

    mo: true,
    tu: true,
    we: true,
    th: true,
    fr: true,
    sa: false,
    su: false,

    start: '08:00',
    stop: '18:00',
    pauseStart: null,
    pauseStop: null,
  },
  ivrTts: {
    ivrExit: '',
    ivrTimeout: 3,
    ivrOptions: {},
    isDetectSpeech: false,
    phrase: '',
    voice: 'oksana',
    emotion: 'good',
  },
  tts: {
    phrase: '',
    voice: 'oksana',
    emotion: 'good',
    speed: 1.2,
  },
};
