<template>
  <v-col cols="12">
    <v-row class="pa-3 ma-n3">
      <v-col md="4" class="mt-2">
        Использовать распознавание речи
      </v-col>
      <v-col cols="12" md="8" class="d-flex align-center ">
        <div class="d-flex align-center justify-center pb-6">
          <v-switch v-model="isDetectSpeechModel" color="primary" hide-details class="ma-0"/>
        </div>
      </v-col>
    </v-row>
    <v-row class="pa-3 ma-n3" v-if="isDetectSpeechModel">
      <v-col v-for="target in speechTargets" :key="target.digit.id" cols="12" sm="4" md="3" lg="2">
        <v-hover v-slot="{ hover }">
          <v-sheet
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
            :elevation="hover ? 6 : 2"
            min-height="200"
            outlined
            rounded
            :class="[
              'd-flex', 'flex-column', 'align-center',
              'justify-center', 'pa-3', 'pt-6', 'sheet',
              'transition-fast-in-fast-out'
            ]"
            @click.stop="openSpeechDialog({ obj: target ? target : null })"
          >
            <div :class="['digit', 'grey--text', { 'text--lighten-2': $vuetify.theme.dark, 'text--darken-2': !$vuetify.theme.dark }]">{{ target.digit.label.slice(0, 20) }} ...</div>

            <v-icon v-if="!target.option" large>mdi-plus-circle-outline</v-icon>

            <template v-else>
              <div v-if="target.option" class="font-weight-bold text-center option-name mb-2">
                {{ target.option.number ? `${target.option.number}: ${target.option.name}` : `${target.option.name.slice(0, 22)} ...` }}
              </div>
              <v-btn text x-small color="red lighten-2" class="delete" @click.stop="removeFromIvr(target.digit.id)">Удалить</v-btn>
            </template>
          </v-sheet>
        </v-hover>
      </v-col>
    </v-row>
    <v-row class="pa-3 ma-n3" v-else>
      <v-col v-for="target in targets" :key="target.digit.id" cols="12" sm="4" md="3" lg="2">
        <v-hover v-slot="{ hover }">
          <v-sheet
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'"
            :elevation="hover ? 6 : 2"
            min-height="200"
            outlined
            rounded
            shaped
            :class="[
              'd-flex', 'flex-column', 'align-center',
              'justify-center', 'pa-3', 'pt-6', 'sheet',
              'transition-fast-in-fast-out'
            ]"
            @click.stop="openDialog({ obj: target ? target : null })"
          >
            <div :class="['digit', 'grey--text', { 'text--lighten-2': $vuetify.theme.dark, 'text--darken-2': !$vuetify.theme.dark }]">{{ target.digit.label }}</div>

            <v-icon v-if="!target.option" large>mdi-plus-circle-outline</v-icon>

            <template v-else>
              <div v-if="target.option" class="font-weight-bold text-center option-name mb-2">
                {{ target.option.number ? `${target.option.number}: ${target.option.name}` : `${target.option.name.slice(0, 22)}...` }}
              </div>
              <v-btn text x-small color="red lighten-2" class="delete" @click.stop="removeFromIvr(target.digit.id)">Удалить</v-btn>
            </template>
          </v-sheet>
        </v-hover>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog.isOpen" scrollable max-width="450px">
      <v-card>
        <v-card-title :class="['text-h5', 'grey', 'lighten-2', { 'grey darken-4' : $vuetify.theme.dark }]">Выберите режим</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            small
            color="primary"
            @click="openSmsDialog({ digit: dialog.obj.digit.id, smsText: dialog.obj.option ? dialog.obj.option.name : null })">
            SMS сообщение
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            small
            color="primary"
            @click="openNumDialog({ digit: dialog.obj.digit.id, internalNumber: dialog.obj.option ? dialog.obj.option.number : null, label: dialog.obj.digit ? dialog.obj.digit.label : null })">
            Направить в ВАТС
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNum.isOpen" scrollable max-width="450px">
      <v-card>
        <v-card-title :class="['text-h5', 'grey', 'lighten-2', { 'grey darken-4' : $vuetify.theme.dark }]">Выберите внутренний номер</v-card-title>
        <v-card-text style="max-height: 450px;">
          <v-radio-group v-model="dialogNum.internalNumber" column>
            <div v-for="({ name, list }, key) in ivrTargets" :key="key" class="mb-2">
              <div class="text-subtitle-1">{{ name }}</div>
              <v-radio
                v-for="target in list"
                :key="target.id"
                :label="arrSelectedIvrTargets.includes(target.number) ? `${target.number} - ${target.name} (выбранный)` : `${target.number} - ${target.name}`"
                :value="target.number"
              />
            </div>
          </v-radio-group>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeNumDialog">Закрыть</v-btn>
          <v-btn color="primary" text @click="saveNumSelected">{{ dialogNum.isChange ? 'Изменить' : 'Добавить' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSms.isOpen" scrollable max-width="450px">
      <v-card>
        <v-card-title :class="['text-h5', 'grey', 'lighten-2', { 'grey darken-4' : $vuetify.theme.dark }]">Введите текст смс</v-card-title>
        <v-card-text style="max-height: 450px;">
          <v-textarea ref="textarea" class="mt-6 ml-3 mr-4" outlined auto-grow counter=255 name="smsText" label="СМС *" v-model="dialogSms.smsText" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeSmsDialog">Закрыть</v-btn>
          <v-btn color="primary" text @click="saveSmsSelected">{{ dialogSms.isChange ? 'Изменить' : 'Добавить' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSpeech.isOpen" scrollable max-width="450px">
      <v-card>
        <v-card-title :class="['text-h5', 'grey', 'lighten-2', { 'grey darken-4' : $vuetify.theme.dark }]">Распознавание речи</v-card-title>
        <v-card-text style="max-height: 450px;">
          Введите слова для распознавание через запятую.
          <v-textarea ref="textarea" class="mt-6 ml-3 mr-4" outlined auto-grow counter=255 name="smsText" v-model="dialogSpeech.newDigit" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeSpeechDialog">Закрыть</v-btn>
          <v-btn color="primary" text @click="saveSpeechSelected">{{ dialogSpeech.isChange ? 'Изменить' : 'Добавить' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { criteriaDigits } from '../../../../../criteria';

export default {
  name: 'IvrOptions',
  props: ['value'],
  data() {
    return {
      dialog: {
        obj: null,
        isOpen: false,
      },
      dialogNum: {
        digit: null,
        internalNumber: null,
        isOpen: false,
        isChange: false,
      },
      dialogSms: {
        digit: null,
        smsText: null,
        isOpen: false,
        isChange: false,
      },
      dialogSpeech: {
        digit: null,
        newDigit: null,
        isOpen: false,
        isChange: false,
      },
    };
  },
  computed: {
    ...mapState({
      ivrTargets: state => state.ivrSettings.ivrTargets,
    }),
    flatListIvrTarget() {
      return this.ivrTargets.reduce((all, cur) => ([...all, ...cur.list]), []);
    },
    arrSelectedIvrTargets() {
      return Object.values(this.value.ivrOptions);
    },
    targets() {
      return criteriaDigits.map(digit => ({
        digit,
        option: this.value.ivrOptions[digit.id]
          ? this.value.ivrOptions[digit.id].type === 'sms' ? { name: this.value.ivrOptions[digit.id].data } : { ...this.flatListIvrTarget.find(t => t.number === this.value.ivrOptions[digit.id].data) }
          : null,
      }));
    },
    speechTargets() {
      const speechDigit = [];
      Object.keys(this.value.ivrOptions).forEach((val) => {
        if ((!['*', '#'].includes(parseInt(val, 16) ? parseInt(val, 16) : val)) && (parseInt(val, 16) !== 0) && !parseInt(val, 16)) {
          speechDigit.push({ id: val, label: val });
        }
      });
      speechDigit.push({ id: 'add+', label: 'Добавить слова' });
      return speechDigit.map(digit => ({
        digit,
        option: this.value.ivrOptions[digit.id]
          ? this.value.ivrOptions[digit.id].type === 'sms' ? { name: this.value.ivrOptions[digit.id].data } : { ...this.flatListIvrTarget.find(t => t.number === this.value.ivrOptions[digit.id].data) }
          : null,
      }));
    },
    isDetectSpeechModel: {
      get() {
        return this.value.isDetectSpeech;
      },
      set(isDetectSpeech) {
        this.$emit('input', { ...this.value, isDetectSpeech });
      },
    },
  },
  methods: {
    isString(val) {
      return (typeof val === 'string' || val instanceof String);
    },
    openDialog({ obj }) {
      this.dialog = {
        obj,
        isOpen: true,
      };
    },
    openSpeechDialog({ obj }) {
      const words = (obj.digit.id === 'add+') ? null : obj.digit.id;
      this.dialogSpeech = {
        digit: words,
        newDigit: words,
        isOpen: true,
        isChange: !!words,
      };
    },
    openNumDialog({ digit, internalNumber }) {
      this.dialogNum = {
        digit,
        internalNumber,
        isOpen: true,
        isChange: !!internalNumber,
      };
    },
    openSmsDialog({ digit, smsText }) {
      this.dialogSms = {
        digit,
        smsText,
        isOpen: true,
        isChange: !!smsText,
      };
    },
    saveNumSelected() {
      const { digit, internalNumber } = this.dialogNum;

      this.addNumToIvr({ digit, internalNumber });
      this.closeDialog();
      this.closeNumDialog();
    },
    saveSmsSelected() {
      const { digit, smsText } = this.dialogSms;

      this.addSmsToIvr({ digit, smsText });
      this.closeDialog();
      this.closeSmsDialog();
    },
    saveSpeechSelected() {
      const { digit, newDigit } = this.dialogSpeech;

      this.dialogSpeech.isOpen = false;
      this.openDialog({ obj: { digit: { id: newDigit, label: newDigit }, option: this.value.ivrOptions[digit] ? this.value.ivrOptions[digit].type === 'sms' ? { name: this.value.ivrOptions[digit].data } : { ...this.flatListIvrTarget.find(t => t.number === this.value.ivrOptions[digit].data) } : null } });
    },
    closeDialog() {
      this.dialog = {
        obj: null,
        isOpen: false,
      };
    },
    closeNumDialog() {
      this.closeDialog();
      this.dialogNum = {
        digit: null,
        target: null,
        isOpen: false,
        isChange: false,
      };
    },
    closeSmsDialog() {
      this.closeDialog();
      this.dialogSms = {
        digit: null,
        target: null,
        isOpen: false,
        isChange: false,
      };
    },
    closeSpeechDialog() {
      this.closeDialog();
      this.dialogSpeech = {
        digit: null,
        isOpen: false,
        isChange: false,
      };
    },
    addNumToIvr({ digit, internalNumber }) {
      this.$emit('input', { ...this.value, ivrOptions: { ...this.value.ivrOptions, [digit]: { data: internalNumber, type: 'num' } } });
    },
    addSmsToIvr({ digit, smsText }) {
      this.$emit('input', { ...this.value, ivrOptions: { ...this.value.ivrOptions, [digit]: { data: smsText, type: 'sms' } } });
    },
    removeFromIvr(digit) {
      const ivrOptions = { ...this.value.ivrOptions };

      delete ivrOptions[digit];

      this.$emit('input', { ...this.value, ivrOptions: { ...ivrOptions } });
    },
  },
};
</script>

<style scoped lang="scss">
.sheet {
  position: relative;
  cursor: pointer;
}
.digit {
  position: absolute;
  top: 0.5em;
  left: 1em;
  font-size: 14px;
  z-index: 1;
}
.option-name,
.option-number {
  line-height: 1.2;
  word-break: break-word;
}
.delete {
  position: absolute;
  bottom: 0.2em;
  right: 0.2em;
}
</style>
