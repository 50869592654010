<script>
import { DateTime, Settings } from 'luxon';

Settings.defaultZone = 'utc';

export default {
  name: 'mixinDatetime',
  props: {
    value: String,
    timezone: Number,
  },
  data() {
    return {
      date: null,
      time: null,
      dateMenu: false,
      timeMenu: false,
    };
  },
  created() {
    this.fromDatetime();
  },
  watch: {
    value() {
      this.fromDatetime();
    },
    date() {
      this.toDatetime();
    },
    time() {
      this.toDatetime();
    },
    timezone() {
      this.toDatetime();
    },
  },
  methods: {
    fromDatetime() {
      if (!this.value) {
        this.date = '';
        this.time = '';

        return;
      }

      this.date = DateTime.fromISO(this.value, { setZone: true }).plus({ hours: this.timezone }).toFormat('yyyy-MM-dd');
      this.time = DateTime.fromISO(this.value, { setZone: true }).plus({ hours: this.timezone }).toFormat('HH:mm');
    },
    toDatetime() {
      const dt = this.date && this.time
        ? DateTime.fromFormat(`${this.date} ${this.time}`, 'yyyy-MM-dd HH:mm').minus({ hour: this.timezone }).toISO()
        : DateTime.fromFormat(`${this.date}`, 'yyyy-MM-dd').toISO();

      if (dt && dt !== this.value) {
        this.$emit('input', dt);
      }
    },
  },
};
</script>
