<script>
import {
  fetchAutocallSettings,
  saveAutocall,
  saveBase,
  saveSchedule,
  saveCriteria,
  saveActions,
  getProgress,
} from './actions';
import mixin from './mixin.vue';
import { actionTypes } from './components/steps/step2/actionTypes';

export default {
  name: 'EditAutocall',
  mixins: [mixin],
  methods: {
    async fetchAutocallSettings() {
      const { groupId, autocallId } = this.$route.params;

      const [
        autocall, bases, ivrTts, ivr,
        tts, audio, schedule, criteria,
      ] = await fetchAutocallSettings({ autocallId, groupId });

      this.autocall = autocall;

      this.bases = bases;
      this.relatedBases = [...this.bases];

      if (ivrTts) {
        this.actions.ivrTts = ivrTts;
        this.actions.type = actionTypes.IVR_TTS.name;
      }

      if (ivr) {
        this.actions.type = actionTypes.IVR.name;
        this.actions.ivr = ivr;
      }

      if (tts) {
        this.actions.type = actionTypes.TTS.name;
        this.actions.tts = tts;
      }

      if (audio) {
        this.actions.audio = audio;
        this.actions.type = actionTypes.AUDIO.name;
      }

      if (schedule) {
        this.schedule = schedule;
      }

      if (criteria) {
        this.criteria = criteria;
      }
    },
    async saveAutocall() {
      try {
        this.autocall = await saveAutocall(this.autocall);
      } catch (e) {
        throw new Error('Не удалось сохранить рассылку');
      }

      try {
        await saveBase({
          bases: this.bases,
          relatedBases: this.relatedBases,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        throw new Error('Не удалось прикрепить базу к рассылке');
      }

      try {
        await saveActions({
          actions: this.actions,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        const { type } = this.actions;

        switch (type) {
          case actionTypes.AUDIO.name:
            throw new Error('Не удалось прикрепить аудиофайл к рассылке');
          case actionTypes.IVR.name:
            throw new Error('Не удалось прикрепить IVR к рассылки');
          case actionTypes.TTS.name:
            throw new Error('Не удалось прикрепить фразу к рассылки');
          default:
            throw new Error('Не удалось настроить IVR у рассылки');
        }
      }

      try {
        await saveSchedule({
          schedule: this.schedule,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        throw new Error('Не удалось настроить расписание у рассылки');
      }

      try {
        await saveCriteria({
          criteria: this.criteria,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        throw new Error('Не удалось настроить критерии у рассылки');
      }

      await getProgress({ autocallId: this.autocall.id, groupId: this.autocall.group });
    },
    resetForm() {
      this.$router.push({ name: 'createAutocall' });
    },
  },
};
</script>
