<template>
  <v-row>
    <v-col cols="12" md="4" class="mt-2">
      Дата начала кампании <span class="red--text">*</span>
    </v-col>
    <v-col cols="12" md="8">
      <v-row>
        <v-col cols="6" lg="8" class="d-flex">
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="35"
            :return-value.sync="date"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                placeholder="2022-22-02"
                hide-details
                dense
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                prepend-inner-icon="mdi-calendar"
              />
            </template>
            <v-date-picker
              v-if="dateMenu"
              v-model="date"
              :min="minDate"
              first-day-of-week=1
              full-width
              @click:date="$refs.dateMenu.save(date)" />
          </v-menu>
        </v-col>

        <v-col cols="6" lg="4" class="d-flex">
          <v-menu
            ref="timeMenu"
            v-model="timeMenu"
            :close-on-content-click="false"
            :nudge-right="35"
            :return-value.sync="time"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                placeholder="08:00"
                hide-details
                dense
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                prepend-inner-icon="mdi-clock-outline"
              />
            </template>
            <v-time-picker
              v-if="timeMenu"
              v-model="time"
              ampm-in-title
              format="24hr"
              full-width
              @click:minute="$refs.timeMenu.save(time)" />
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime, Settings } from 'luxon';
import mixinDatetime from './mixinDatetime.vue';

Settings.defaultZone = 'utc';

export default {
  name: 'StartDatetime',
  mixins: [mixinDatetime],
  computed: {
    minDate() {
      return DateTime.local().toISO();
    },
  },
};
</script>
