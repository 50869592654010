<template>
  <div class="mb-12">
    <v-stepper-step :step="2" :complete="currentStep > 2" :editable="currentStep > 2">
      Настройка кампании
    </v-stepper-step>

    <v-stepper-content :step="2">
      <v-row class="pt-1">
        <v-col cols="12">
          <v-select :items="arrActionTypes" v-model="actionTypeModel" outlined hide-details dense label="Выберите действие" item-text="label" item-value="name"/>
        </v-col>

        <AudioUploader v-if="actionTypeModel === actionTypes.AUDIO.name" v-model="audioModel"/>

        <Tts v-else-if="actionTypeModel === actionTypes.TTS.name" v-model="ttsModel" :bases="bases" :actions="actions" />

        <Ivr v-else-if="actionTypeModel === actionTypes.IVR.name" v-model="ivrModel" :bases="bases" />

        <IvrTts v-else v-model="ivrTtsModel" :active-type="actionTypeModel" :bases="bases"/>

        <v-col cols="12">
          <div class="d-flex mt-5">
            <v-btn outlined color="primary" dark class="mr-4" @click="$emit('prev')">Назад</v-btn>
            <v-btn color="hemp" dark :disabled="isNotAvailableNext" @click="$emit('next')">Продолжить</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import AudioUploader from './components/AudioUploader.vue';
import IvrTts from './components/ivrTts/Index.vue';
import Tts from './components/Tts/Index.vue';
import Ivr from './components/Ivr.vue';
import { actionTypes } from './actionTypes';
import { isValidActions } from '../../../validation';

export default {
  name: 'AutocallStep2',
  components: { AudioUploader, IvrTts, Tts, Ivr },
  props: {
    editable: Boolean,
    currentStep: Number,
    bases: Array,
    actions: Object,
  },
  data() {
    return {
      actionTypes,
    };
  },
  computed: {
    isNotAvailableNext() {
      return !isValidActions(this.actions);
    },
    arrActionTypes() {
      return Object.values(this.actionTypes);
    },
    actionTypeModel: {
      get() {
        return this.actions.type;
      },
      set(type) {
        this.updateActions('type', type);
      },
    },
    audioModel: {
      get() {
        return this.actions.audio;
      },
      set(audio) {
        this.updateActions('audio', audio);
      },
    },
    ivrTtsModel: {
      get() {
        return this.actions.ivrTts;
      },
      set(value) {
        if (typeof value === 'string') {
          this.updateActions('ivrTts', { ...this.actions.ivrTts, phrase: value });
        } else {
          this.updateActions('ivrTts', { ...this.actions.ivrTts, ...value });
        }
      },
    },
    ttsModel: {
      get() {
        return this.actions.tts;
      },
      set(value) {
        if (typeof value === 'string') {
          this.updateActions('tts', { ...this.actions.tts, phrase: value });
        } else {
          this.updateActions('tts', { ...this.actions.tts, ...value });
        }
      },
    },
    ivrModel: {
      get() {
        return this.actions.ivr.num;
      },
      set(num) {
        this.updateActions('ivr', { ...this.actions.ivr, num });
      },
    },
  },
  methods: {
    updateActions(field, value) {
      this.$emit('update:actions', { ...this.actions, [field]: value });
    },
  },
};
</script>
