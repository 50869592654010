<template>
  <div class="col-12">
    <v-row>
      <v-col md="2">
        <v-select
          v-model="modelVoice"
          :items="this.TTS_GET_VOICES"
          item-text="voiceName"
          item-value="voice"
          @input="changedVoice"
          dense
          outlined
          single-line
          menu-props="auto"
          prepend-icon="mdi-robot"
          :rules="[rules.required]"
          class="ml-3"
          label="Выберите голос"
        ></v-select>
      </v-col>
      <v-col md="3">
        <v-select
          v-model="modelEmotion"
          :items="this.emotions"
          item-text="description"
          item-value="emotion"
          dense
          outlined
          single-line
          menu-props="auto"
          prepend-icon="mdi-robot-angry"
          :rules="[rules.required]"
          label="Выберите эмоцию голоса"
        ></v-select>
      </v-col>
      <v-col md="4">
        <v-slider
          prepend-icon="mdi-speedometer"
          thumb-label="always"
          thumb-size="22"
          min="0.1"
          max="3.0"
          step="0.1"
          track-color="grey lighten-1"
          v-model="modelSpeed"
        >
        </v-slider>
      </v-col>
      <v-col md="2">
        <v-btn
          class="mr-4"
          color="primary"
          outlined
          small
          right
          height="40px"
          width="230px"
          :loading="ttsFileWait"
          :disabled="ttsWork"
          @click="ttsGen"
        >Озвучить
          <v-icon
          right
          dark
          >mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <vuetify-audio
    color="primary"
    :file="ttsFile"
    flat
    autoPlay
    class="ml-3 mr-4"
    ></vuetify-audio>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { rules } from '../../../../../../../formValidation';

export default {
  name: 'TtsSettings',
  props: {
    tts: Object,
  },
  components: {
    VuetifyAudio: () => import('vuetify-audio'),
  },
  data() {
    return {
      rules: {
        required: rules.required,
      },
      emotions: [],
      ttsFile: null,
      ttsFileWait: false,
    };
  },
  computed: {
    ...mapGetters(['TTS_GET_VOICES', 'TTS_GET_VOICE_EMOTIONS']),
    ttsWork() {
      return !(this.tts.phrase.trim() && this.tts.emotion?.trim());
    },
    modelVoice: {
      get() {
        this.changedVoice(this.tts.voice);
        return this.tts.voice;
      },
      set(value) {
        this.$emit('input', { voice: value, emotion: '' });
      },
    },
    modelEmotion: {
      get() {
        return this.tts.emotion;
      },
      set(value) {
        this.$emit('input', { emotion: value });
      },
    },
    modelSpeed: {
      get() {
        return this.tts.speed;
      },
      set(value) {
        this.$emit('input', { speed: value });
      },
    },
  },
  methods: {
    ...mapMutations(['SET_VOICE', 'SET_EMOTION', 'SET_SPEED']),
    ...mapActions(['GENERATE_TTS']),
    changedVoice(voice) {
      const emo = this.TTS_GET_VOICE_EMOTIONS(voice);
      this.emotions = emo.length === 1 ? emo[0].emotions : [];
    },
    changeAwaitButton() {
      this.ttsFileWait = !this.ttsFileWait;
    },
    async ttsGen() {
      this.changeAwaitButton();
      this.ttsFile = await this.GENERATE_TTS(this.tts);
      this.changeAwaitButton();
    },
  },
};
</script>
