import store from '../../store';
import { actionTypes } from './components/steps/step2/actionTypes';

const fetchAutocallSettings = ({ autocallId, groupId }) => (
  Promise.all([
    store.dispatch('GET_SETTINGS_AUTOCALL', { groupId, autocallId }),
    store.dispatch('GET_BASES_IN_AUTOCALL', { groupId, autocallId }),
    store.dispatch('GET_IVR_TTS', { groupId, autocallId }),
    store.dispatch('GET_IVR', { groupId, autocallId }),
    store.dispatch('GET_TTS_PHRASE', { groupId, autocallId }),
    store.dispatch('GET_AUDIO_BY_AUTOCALL', { groupId, autocallId }),
    store.dispatch('GET_SCHEDULE', { groupId, autocallId }),
    store.dispatch('GET_LIST_CRITERIES', { groupId, autocallId }),
  ])
);

const saveAutocall = autocall => {
  try {
    return store.dispatch(autocall.id ? 'UPDATE_AUTOCALL' : 'CREATE_AUTOCALL', autocall);
  } catch (e) {
    throw e;
  }
};

const saveBase = ({ bases, relatedBases = [], autocallId, groupId }) => {
  try {
    if (relatedBases.length) {
      // удалим прошлые не используемые базы номеров
      Promise.all(
        relatedBases
          .filter(b => !bases.includes(b))
          .map(baseId => store.dispatch('DELETE_BASE_IN_AUTOCALL', {
            groupId,
            autocallId,
            baseId,
          })),
      );
    }

    // добавим новые
    Promise.all(
      bases
        .filter(b => !relatedBases.includes(b))
        .map(baseId => store.dispatch('ADD_BASE_IN_AUTOCALL', {
          groupId,
          autocallId,
          baseId,
        })),
    );
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const saveSchedule = ({ schedule, autocallId, groupId }) => {
  try {
    store.dispatch(schedule.id ? 'UPDATE_SCHEDULE' : 'CREATE_SCHEDULE', {
      groupId,
      autocallId,
      schedule,
    });
  } catch (e) {
    throw e;
  }
};

function saveAudio({ groupId, autocallId, audio }) {
  return store.dispatch(audio?.id ? 'UPDATE_AUDIO_IN_AUTOCALL' : 'ADD_AUDIO_IN_AUTOCALL', {
    groupId,
    autocallId,
    audio,
  });
}

function saveTts({ groupId, autocallId, tts }) {
  return store.dispatch(tts.id ? 'UPDATE_TTS_PHRASE' : 'ADD_TTS_PHRASE', {
    groupId,
    autocallId,
    ttsId: tts.id,
    phrase: tts.phrase,
    voice: tts.voice,
    emotion: tts.emotion,
    speed: tts.speed,
  });
}

function saveIvr({ groupId, autocallId, ivr }) {
  return store.dispatch(ivr.id ? 'UPDATE_IVR' : 'ADD_IVR', {
    groupId,
    autocallId,
    ivrId: ivr.id,
    num: ivr.num,
  });
}

function saveIvrTts({ groupId, autocallId, ivrTts, type }) {
  const ivrTtsData = ivrTts;

  if (type === actionTypes.IVR.name) {
    ivrTtsData.ttsPhrase = '';
  }

  return store.dispatch(ivrTts.id ? 'UPDATE_IVR_TTS' : 'CREATE_IVR_TTS', {
    groupId,
    autocallId,
    ivrTts: ivrTtsData,
  });
}

function checkAndDeleteAudio({ groupId, autocallId, audioId }) {
  if (audioId) {
    store.dispatch('DELETE_AUDIO_IN_AUTOCALL', { autocallId, groupId, audioId });
  }
}

function checkAndDeleteIvrTts({ autocallId, groupId, ivrTtsId }) {
  if (ivrTtsId) {
    store.dispatch('DELETE_IVR_TTS', { autocallId, groupId, ivrTtsId });
  }
}

function checkAndDeleteTtsPhrase({ autocallId, groupId, ttsId }) {
  if (ttsId) {
    store.dispatch('DELETE_TTS_PHRASE', { autocallId, groupId, ttsId });
  }
}

function checkAndDeleteIvr({ autocallId, groupId, ivrId }) {
  if (ivrId) {
    store.dispatch('DELETE_IVR', { autocallId, groupId, ivrId });
  }
}

const saveActions = async ({ autocallId, groupId, actions }) => {
  const {
    ivrTts, tts, audio, ivr, type,
  } = actions;

  try {
    switch (actions.type) {
      case actionTypes.AUDIO.name:
        checkAndDeleteIvrTts({ autocallId, groupId, ivrTtsId: ivrTts?.id });
        checkAndDeleteIvr({ autocallId, groupId, ivrId: ivr?.id });
        checkAndDeleteTtsPhrase({ autocallId, groupId, ttsId: tts?.id });

        return await saveAudio({ autocallId, groupId, audio });
      case actionTypes.TTS.name:
        checkAndDeleteIvrTts({ autocallId, groupId, ivrTtsId: ivrTts?.id });
        checkAndDeleteIvr({ autocallId, groupId, ivrId: ivr?.id });
        checkAndDeleteAudio({ autocallId, groupId, audioId: audio?.id });

        return await saveTts({ autocallId, groupId, tts });
      case actionTypes.IVR.name:
        checkAndDeleteIvrTts({ autocallId, groupId, ivrTtsId: ivrTts?.id });
        checkAndDeleteTtsPhrase({ autocallId, groupId, ttsId: tts?.id });
        checkAndDeleteAudio({ autocallId, groupId, audioId: audio?.id });

        return await saveIvr({ autocallId, groupId, ivr });
      default: // IVR_TTS
        checkAndDeleteTtsPhrase({ autocallId, groupId, ttsId: tts?.id });
        checkAndDeleteAudio({ autocallId, groupId, audioId: audio?.id });
        checkAndDeleteIvr({ autocallId, groupId, ivrId: ivr?.id });

        return await saveIvrTts({ autocallId, groupId, ivrTts, type });
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const saveCriteria = ({ criteria, autocallId, groupId }) => {
  try {
    store.dispatch(criteria.id ? 'UPDATE_SETTINGS_CRITERION' : 'CREATE_CRITERION', {
      groupId,
      autocallId,
      criteria,
    });
  } catch (e) {
    throw e;
  }
};

const getProgress = ({ autocallId, groupId }) => {
  try {
    store.dispatch('GET_AUTOCALL_PROGRESS', { groupId, autocallId });
  } catch (e) {
    throw e;
  }
};

export {
  fetchAutocallSettings,
  saveAutocall,
  saveBase,
  saveCriteria,
  saveSchedule,
  saveActions,
  getProgress,
};
