<template>
  <v-col cols="12">
    <v-row class="mt-1">
      <v-col cols="12" md="4" class="mt-2">
        Время ожидания ввода пользователя до выполнения выхода <span class="red--text">*</span>
      </v-col>
      <v-col cols="12" md="8" class="d-flex align-center">
        <v-slider min="0" max="10" hide-details track-color="grey lighten-1" v-model="model">
          <template v-slot:append>
            <div class="d-flex align-center">
              <v-text-field v-model="model" dense class="mt-0 pt-0" hide-details type="number" style="width: 60px"/>
              сек.
            </div>
          </template>
        </v-slider>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'IvrTimeout',
  props: ['value'],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
