<template>
  <v-container>
    <!-- <FunctionalityUnavailabilityNotification /> -->

    <v-row>
      <v-col class="mx-n2">
        <router-link to="/"><v-btn color="primary" dark class="ma-2"><v-icon left dark>mdi-keyboard-backspace</v-icon>Назад</v-btn></router-link>
        <v-btn class="mx-2" color="tomato" dark @click="resetForm"><v-icon left dark>mdi-autorenew</v-icon>Очистить форму</v-btn>
        <v-btn class="mx-2" color="hemp" dark @click="redirectToLP"><v-icon left dark>mdi-view-list</v-icon>Инструкция</v-btn>
<!--        <v-btn class="mx-2" disabled>Анкетирование</v-btn>-->
<!--        <v-btn class="mx-2" disabled>Добавить шаблон</v-btn>-->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8" class="pb-0">
        <v-text-field
          outlined
          label="Название кампании *"
          :rules="[rules.required, rules.min(2), rules.max(255)]"
          v-model="autocall.name"
        />
      </v-col>
      <v-col cols="12" md="4" class="d-flex align-center pb-0" v-if="GROUPS">
        <v-select
          ref="selectGroup"
          v-model="autocall.group"
          :items="GROUPS"
          label="Выберите группу *"
          item-value="id"
          item-text="name"
          outlined
          :rules="[rules.required]"
        >
          <template v-slot:prepend-item>
            <v-list-item link @click="$router.push({ name: 'groups' })">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon left>mdi-pencil</v-icon> Редактирование групп
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <DialogAddGroup @create="createGroupEvent"/>

            <v-divider class="mt-2"></v-divider>

          </template>
          <template v-slot:append-outer>
            <v-tooltip right max-width="450px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
              </template>
              <div class="text-caption">
                Выберите одну из существующих групп или создайте новую группу, в которой будут объединены созданные вами кампании.
              </div>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="8">
        <v-text-field
          outlined
          label="Комментарий"
          hide-details
          v-model="autocall.comment"
        />
      </v-col>
    </v-row>

    <v-row v-if="loadingData" class="fill-height" align-content="center" justify="center">
      <v-col cols="12" md="6">
        <v-progress-linear color="primary" class="mt-5" indeterminate rounded height="6"></v-progress-linear>
      </v-col>
    </v-row>

    <Stepper
      v-else
      ref="Steper"
      :bases.sync="bases"
      :schedule.sync="schedule"
      :autocall.sync="autocall"
      :actions.sync="actions"
      :criteria.sync="criteria"
    />

    <v-row>
      <v-col cols="12" class="text-caption red--text">* - обязательные для заполнения поля.</v-col>
    </v-row>

    <div class="d-flex mt-5 mb-10 mx-n2">
      <v-btn color="hemp" dark outlined class="ma-2" x-large :loading="loadingSave" :disabled="submitLoading || !enableSaveAutocall" @click="save">
        Сохранить
      </v-btn>
      <v-btn
        color="hemp"
        class="ma-2"
        dark
        x-large
        :loading="loadingPlay"
        :disabled="submitLoading || !enableSaveAutocall || !AUTOCALL_PACKAGE_NAME || !AUTOCALL_TARIFF_NAME"
        @click="saveAndPlay"
      >
        Стартовать
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
// import FunctionalityUnavailabilityNotification from '../../components/FunctionalityUnavailabilityNotification.vue';
import Stepper from './components/Stepper.vue';
import DialogAddGroup from './components/DialogAddGroup.vue';
import defaultSettings from './defaultSettings';
import { actionTypes } from './components/steps/step2/actionTypes';
import { isValidData } from './validation';
import { rules } from '../../formValidation';

export default {
  name: 'mixin',
  components: {
    // FunctionalityUnavailabilityNotification,
    Stepper,
    DialogAddGroup,
  },
  data() {
    return {
      rules: {
        required: rules.required,
        min: rules.min,
        max: rules.max,
      },

      loadingData: false,

      loadingSave: false,
      loadingPlay: false,

      autocall: {
        ...defaultSettings.autocall,
      },

      relatedBases: [],
      bases: [],

      schedule: {
        ...defaultSettings.schedule,
      },

      criteria: {},

      actions: {
        type: actionTypes.AUDIO.name,
        audio: null,
        ivrTts: {
          ...defaultSettings.ivrTts,
        },
        tts: {
          ...defaultSettings.tts,
        },
        ivr: {
          num: '',
        },
      },
    };
  },
  computed: {
    ...mapState({
      trashNumbers: state => state.trashNumbers.trashNumbers,
      userExternalNumbers: state => state.trashNumbers.userExternalNumbers,
    }),
    ...mapGetters(['GROUPS', 'AUTOCALL_PACKAGE_NAME', 'AUTOCALL_TARIFF_NAME']),
    submitLoading() {
      return this.loadingSave || this.loadingPlay;
    },
    enableSaveAutocall() {
      return isValidData({
        autocall: this.autocall,
        actions: this.actions,
        schedule: this.schedule,
      })
        && this.bases.length;
    },
  },
  created() {
    this.loadingData = true;

    if (!this.userExternalNumbers.length || !this.trashNumbers.length) {
      this.GET_TRASH_NUMBERS();
    }

    this.fetchIvrSettings();

    this.fetchAutocallSettings()
      .catch(e => {
        console.log(e);
        this.$notify({ text: 'Что-то пошло не так, перезагрузите страницу!', type: 'error' });
      })
      .finally(() => { this.loadingData = false; });
  },
  methods: {
    ...mapActions(['GET_TARGET_NUMBERS', 'START_AUTOCALL', 'GET_TRASH_NUMBERS']),
    fetchAutocallSettings() {},
    async fetchIvrSettings() {
      // await this.GET_IVR_SETTINGS();
      await this.GET_TARGET_NUMBERS();
    },
    async save() {
      try {
        this.loadingSave = true;

        await this.saveAutocall();

        this.$notify({ text: 'Кампания успешно сохранена!', type: 'success' });

        this.$router.push({ name: 'home' });
      } catch (e) {
        this.$notify({ text: e?.message ?? 'Не удалось сохранить кампанию!', type: 'error' });
      } finally {
        this.loadingSave = false;
      }
    },
    async saveAndPlay() {
      try {
        this.loadingPlay = true;

        await this.saveAutocall();

        this.$notify({ text: 'Кампания сохранена!', type: 'success' });

        await this.START_AUTOCALL({ groupId: this.autocall.group, autocallId: this.autocall.id });

        this.$notify({ text: 'Кампания запущена!', type: 'success' });

        this.$router.push({ name: 'home' });
      } catch (e) {
        this.$notify({ text: e?.message ?? 'Не удалось запустить кампанию!', type: 'error' });
      } finally {
        this.loadingPlay = false;
      }
    },
    createGroupEvent(group) {
      this.$refs.selectGroup.blur();
      this.autocall.group = group.id;
    },
    resetForm() {},
    redirectToLP() {
      window.open('https://telezon.ru/for-clients/instruktsii/instruktsiya-avtooobzvon.html', '_blank');
    },
  },
};
</script>
