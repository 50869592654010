<template>
  <v-row>
    <v-col cols="12" md="4" class="mt-2">
      Периодичность повторения <span class="red--text">*</span>
      <v-tooltip right max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
        </template>
        <div class="text-caption">
          При выборе "В цикле":
          <br>
          - Система будет пытаться дозвониться до неуспешных номеров по окончанию кампании
          <br>
          - Между циклами выдерживается пауза 10 минут.
        </div>
      </v-tooltip>
    </v-col>
    <v-col cols="12" :md="modelPeriod === 'loop' ? 4 : 8" class="d-flex">
      <v-select :items="PERIODS" v-model="modelPeriod" item-value="id" item-text="label" label="Период" outlined hide-details dense prepend-inner-icon="mdi-arrow-left-right-bold" />
    </v-col>
    <v-col v-if="modelPeriod === 'loop'" cols="12" md="4"  class="d-flex align-center">
      <v-slider min="1" max="100" hide-details track-color="grey lighten-1" v-model="modelLoop">
        <template v-slot:append>
          <v-text-field v-model="modelLoop" dense class="mt-0 pt-0" hide-details type="number" style="width: 60px" />
        </template>
      </v-slider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PeriodRepeat',
  props: {
    value: Object,
  },
  computed: {
    ...mapGetters(['PERIODS']),
    modelPeriod: {
      get() {
        const period = this.PERIODS.find(p => p.id === this.value.autoRestart);

        return period ? period.id : null;
      },
      set(autoRestart) {
        this.$emit('input', { ...this.value, autoRestart });
      },
    },
    modelLoop: {
      get() {
        return this.value.countLoop;
      },
      set(countLoop) {
        this.$emit('input', { ...this.value, countLoop });
      },
    },
  },
};
</script>
