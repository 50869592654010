<template>
  <v-row>
    <v-col cols="12" md="4" class="mt-2">
      Количество вызовов в 1 час
     <v-tooltip right max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
        </template>
        <div class="text-caption">
          Например, при выставлении скорости в 600 вызовов в час, каждые 6 секунд будет инициироваться новый вызов номера из списка.
        </div>
      </v-tooltip>
    </v-col>
    <v-col cols="12" md="8" class="d-flex">
      <v-slider min="1" max="600" hide-details track-color="grey lighten-1" v-model="model">
        <template v-slot:append>
          <v-text-field v-model="model" dense class="mt-0 pt-0" hide-details type="number" style="width: 60px" />
        </template>
      </v-slider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Speed',
  props: {
    value: [Number, String],
  },
  computed: {
    model: {
      get() {
        return +this.value;
      },
      set(speed) {
        this.$emit('input', speed);
      },
    },
  },
};
</script>
