<template>
  <v-row>
    <v-col>
      <v-stepper v-model="currentStep" vertical>
        <AutocallStep1
          :current-step="currentStep"
          :used-bases.sync="basesModel"
          :type-upload.sync="typeUpload"
          @next="currentStep = 2"
        />
        <AutocallStep2
          :current-step="currentStep"
          :bases="basesModel"
          :actions.sync="actionsModel"
          @prev="currentStep = 1"
          @next="currentStep = 3"
        />
        <AutocallStep3
          :current-step="currentStep"
          :autocall.sync="autocallModel"
          :schedule.sync="scheduleModel"
          :criteria.sync="criteriaModel"
          :ivr-options="actions.ivrTts.ivrOptions"
          @prev="currentStep = 2"
        />
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
import AutocallStep1 from './steps/step1/AutocallStep1.vue';
import AutocallStep2 from './steps/step2/AutocallStep2.vue';
import AutocallStep3 from './steps/step3/AutocallStep3.vue';
import typesUploadBase from './steps/step1/types';

export default {
  name: 'Stepper',
  props: {
    bases: Array,
    schedule: Object,
    autocall: Object,
    criteria: Object,
    actions: Object,
  },
  components: {
    AutocallStep1,
    AutocallStep2,
    AutocallStep3,
  },
  data() {
    return {
      typeUpload: typesUploadBase.IS_UPLOAD,

      currentStep: 1,
    };
  },
  computed: {
    autocallModel: {
      get() {
        return this.autocall;
      },
      set(v) {
        this.$emit('update:autocall', v);
      },
    },
    basesModel: {
      get() {
        return this.bases;
      },
      set(bases) {
        this.$emit('update:bases', bases);
      },
    },
    actionsModel: {
      get() {
        return this.actions;
      },
      set(actions) {
        this.$emit('update:actions', actions);
      },
    },
    scheduleModel: {
      get() {
        return this.schedule;
      },
      set(v) {
        this.$emit('update:schedule', v);
      },
    },
    criteriaModel: {
      get() {
        return this.criteria;
      },
      set(v) {
        this.$emit('update:criteria', v);
      },
    },
  },
  created() {
    if (this.bases.length) {
      this.typeUpload = typesUploadBase.IS_SELECT;
    }
  },
  watch: {
    bases(b) {
      if (b.length) {
        this.typeUpload = typesUploadBase.IS_SELECT;
      }
    },
  },
};
</script>
