<template>
  <v-row>
    <v-col cols="12" md="12" lg="4" class="mt-2">
      Временные рамки работы кампании
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="3" class="d-flex align-center">
      <span class="mr-2">c</span>
      <v-select
        :items="WEEK_DAYS_NAME"
        item-text="label"
        placeholder="пн"
        item-value="id"
        v-model="startDayModel"
        outlined
        hide-details
        dense
        class="day"
      />
      <span class="ml-5 mr-2">по</span>
      <v-select
        :items="filteredWeekDaysName"
        item-text="label"
        placeholder="пт"
        item-value="id"
        v-model="endDayModel"
        outlined hide-details dense class="day"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="3" class="d-flex align-center">
      <span class="mr-2">c</span>
      <v-menu
        ref="startTimeMenu"
        v-model="startTimeMenu"
        :close-on-content-click="false"
        :nudge-right="35"
        :return-value.sync="startTimeModel"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="startTimeModel" placeholder="08:00" hide-details dense outlined readonly v-bind="attrs" v-on="on" class="time" />
        </template>
        <v-time-picker
          v-if="startTimeMenu"
          v-model="startTimeModel"
          ampm-in-title format="24hr"
          full-width
          @click:minute="$refs.startTimeMenu.save(startTimeModel)"
        />
      </v-menu>

      <span class="ml-5 mr-2">по</span>
      <v-menu
        ref="stopTimeMenu"
        v-model="stopTimeMenu"
        :close-on-content-click="false"
        :nudge-right="35"
        :return-value.sync="stopTimeModel"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="stopTimeModel" placeholder="18:00" hide-details dense outlined readonly v-bind="attrs" v-on="on" class="time" />
        </template>
        <v-time-picker
          v-if="stopTimeMenu"
          v-model="stopTimeModel"
          ampm-in-title format="24hr"
          full-width
          @click:minute="$refs.stopTimeMenu.save(stopTimeModel)"
        />
      </v-menu>
    </v-col>

    <v-col cols="12" md="4" lg="2" class="d-flex">
      <v-select :items="timezones" item-text="label" item-value="value" label="Временная зона" v-model="timezoneModel" outlined hide-details dense />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import timezones from '../../../../timezones';

export default {
  name: 'SelectTime',
  props: {
    weekDays: Object,
    time: Object,
    timezone: Number,
  },
  data() {
    return {
      timezones,

      startTimeMenu: false,
      stopTimeMenu: false,
    };
  },
  computed: {
    ...mapGetters(['WEEK_DAYS_NAME']),
    filteredWeekDaysName() {
      return this.WEEK_DAYS_NAME.map(day => (day.id < this.startDayModel ? { ...day, disabled: true } : day));
    },
    startDayModel: {
      get() {
        return this.weekDays.start;
      },
      set(dayId) {
        this.$emit('update:week-days', { start: dayId, end: (this.endDayModel && dayId > this.endDayModel) ? dayId : this.endDayModel });
      },
    },
    endDayModel: {
      get() {
        return this.weekDays.end;
      },
      set(dayId) {
        this.$emit('update:week-days', { start: this.startDayModel, end: dayId });
      },
    },
    startTimeModel: {
      get() {
        return this.time.start ? DateTime.fromISO(this.time.start).toFormat('HH:mm') : '';
      },
      set(time) {
        this.$emit('update:time', { start: time, stop: this.stopTimeModel });
      },
    },
    stopTimeModel: {
      get() {
        return this.time.stop ? DateTime.fromISO(this.time.stop).toFormat('HH:mm') : '';
      },
      set(time) {
        this.$emit('update:time', { start: this.startTimeModel, stop: time });
      },
    },
    timezoneModel: {
      get() {
        return this.timezone;
      },
      set(timezone) {
        this.$emit('update:timezone', timezone);
      },
    },
  },
};
</script>

<style scoped>
  .day, .time {
    max-width: 110px;
  }
</style>
