<template>
  <v-col cols="12">
    <v-row>
      <IvrTtsExit v-model="ivrExitModel" />

      <IvrTtsTimeout v-model="ivrTimeoutModel" />

      <IvrTtsOptions v-model="ivrOptionsModel" />

      <Tts v-if="isVisibleTts" v-model="ttsModel" :bases="bases"/>
    </v-row>
  </v-col>
</template>

<script>
import { actionTypes } from '../../actionTypes';
import IvrTtsExit from './IvrTtsExit.vue';
import IvrTtsTimeout from './IvrTtsTimeout.vue';
import IvrTtsOptions from './IvrTtsOptions.vue';
import Tts from '../Tts/Index.vue';

export default {
  name: 'IvrTts',
  components: {
    IvrTtsExit, IvrTtsTimeout, IvrTtsOptions, Tts,
  },
  props: {
    activeType: String,
    bases: Array,
    value: Object,
  },
  computed: {
    isVisibleTts() {
      return this.activeType === actionTypes.IVR_TTS.name;
    },
    ttsModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    ivrExitModel: {
      get() {
        return this.value.ivrExit;
      },
      set(ivrExit) {
        this.$emit('input', { ...this.value, ivrExit });
      },
    },
    ivrTimeoutModel: {
      get() {
        return this.value.ivrTimeout;
      },
      set(ivrTimeout) {
        this.$emit('input', { ...this.value, ivrTimeout });
      },
    },
    ivrOptionsModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
