<script>
import {
  fetchAutocallSettings,
  saveAutocall,
  saveBase,
  saveSchedule,
  saveCriteria,
  saveActions,
  getProgress,
} from './actions';
import defaultSettings from './defaultSettings';
import mixin from './mixin.vue';
import { actionTypes } from './components/steps/step2/actionTypes';

export default {
  name: 'CreateAutocall',
  mixins: [mixin],
  methods: {
    async fetchAutocallSettings() {
      const { groupId, autocallId } = this.$route.params;

      if (autocallId) {
        const [autocall, bases, ivrTts, ivr, tts, schedule, criteria] = await fetchAutocallSettings({ autocallId, groupId });

        if (autocall) {
          delete autocall.id;
          this.autocall = autocall;
        }

        this.bases = bases;

        if (ivrTts) {
          delete ivrTts.id;
          delete ivrTts.calling;
          this.actions.ivrTts = ivrTts;
          this.actions.type = actionTypes.IVR_TTS.name;
        }

        if (ivr) {
          this.actions.type = actionTypes.IVR.name;
          this.actions.ivr.num = ivr.num;
        }

        if (tts) {
          this.actions.type = actionTypes.TTS.name;
          this.actions.tts.phrase = tts.phrase;
        }

        if (schedule) {
          delete schedule.id;
          this.schedule = schedule;
        }

        if (criteria) {
          delete criteria.id;
          this.criteria = criteria;
        }

        this.autocall.name = `Копия_${this.autocall.name}`;
      }
    },
    async saveAutocall() {
      try {
        this.autocall = await saveAutocall(this.autocall);
      } catch (e) {
        throw new Error('Не удалось сохранить рассылку');
      }

      try {
        await saveBase({
          bases: this.bases,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        throw new Error('Не удалось прикрепить базу к рассылке');
      }

      try {
        await saveActions({
          actions: this.actions,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        const { type } = this.actions;

        switch (type) {
          case actionTypes.AUDIO.name:
            throw new Error('Не удалось прикрепить аудиофайл к рассылке');
          case actionTypes.IVR.name:
            throw new Error('Не удалось прикрепить IVR к рассылки');
          case actionTypes.TTS.name:
            throw new Error('Не удалось прикрепить фразу к рассылки');
          default:
            throw new Error('Не удалось настроить IVR у рассылки');
        }
      }

      try {
        await saveSchedule({
          schedule: this.schedule,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        throw new Error('Не удалось настроить расписание у рассылки');
      }

      try {
        await saveCriteria({
          criteria: this.criteria,
          autocallId: this.autocall.id,
          groupId: this.autocall.group,
        });
      } catch (e) {
        throw new Error('Не удалось настроить критерии у рассылки');
      }

      await getProgress({ autocallId: this.autocall.id, groupId: this.autocall.group });
    },
    resetForm() {
      this.autocall = { ...defaultSettings.autocall };
      this.bases = [];
      this.schedule = { ...defaultSettings.schedule };
      this.criteria = {};
      this.relatedBases = [];

      this.$refs.Steper.currentStep = 1;
    },
  },
};
</script>
