<template>
  <v-row class="mt-1">
    <v-col cols="12" md="4" lg="4" class="mt-2">
      Прервать кампанию в указанное время
      <v-tooltip right max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
        </template>
        <div class="text-caption">
          В указанный интервал времени кампания будет автоматически остановлена. Например, вы можете указать обеденные часы сотрудников.
        </div>
      </v-tooltip>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="3" class="d-flex align-center">
      <span class="mr-2">c</span>
      <v-menu
        ref="startTimeMenu"
        v-model="startTimeMenu"
        :close-on-content-click="false"
        :nudge-right="35"
        :return-value.sync="startPauseModel"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startPauseModel"
            placeholder="12:00"
            hide-details
            dense
            outlined
            readonly
            v-bind="attrs"
            v-on="on"
            class="time"
          />
        </template>
        <v-time-picker
          v-if="startTimeMenu"
          v-model="startPauseModel"
          ampm-in-title format="24hr"
          full-width
          @click:minute="$refs.startTimeMenu.save(startPauseModel)"
        />
      </v-menu>

      <span class="ml-5 mr-2">по</span>
      <v-menu
        ref="stopTimeMenu"
        v-model="stopTimeMenu"
        :close-on-content-click="false"
        :nudge-right="35"
        :return-value.sync="stopPauseModel"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="stopPauseModel" placeholder="14:00" hide-details dense outlined readonly v-bind="attrs" v-on="on" class="time" />
        </template>
        <v-time-picker
          v-if="stopTimeMenu"
          v-model="stopPauseModel"
          ampm-in-title format="24hr"
          full-width
          @click:minute="$refs.stopTimeMenu.save(stopPauseModel)"
        />
      </v-menu>
    </v-col>
    <v-col cols="12" md="4" lg="2" class="d-flex">
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'ExceptionsTime',
  props: {
    time: Object,
  },
  data() {
    return {
      startTimeMenu: false,
      stopTimeMenu: false,
    };
  },
  computed: {
    startPauseModel: {
      get() {
        return this.time.pauseStart ? DateTime.fromISO(this.time.pauseStart).toFormat('HH:mm') : '';
      },
      set(time) {
        this.$emit('update:time', { pauseStart: time, pauseStop: this.stopPauseModel });
      },
    },
    stopPauseModel: {
      get() {
        return this.time.pauseStop ? DateTime.fromISO(this.time.pauseStop).toFormat('HH:mm') : '';
      },
      set(time) {
        this.$emit('update:time', { pauseStart: this.startPauseModel, pauseStop: time });
      },
    },
  },
};
</script>

<style scoped>
  .time {
    max-width: 110px;
  }
</style>
