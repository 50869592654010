<template>
  <v-row>
    <v-col cols="12" md="4" class="mt-2">
      Какой вызов считать успешным? <span class="red--text">*</span>
      <v-tooltip right max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
        </template>
        <div class="text-caption">
          Выбранный параметр позволит проанализировать данные по завершению рекламной кампании и подготовить отчет со статистикой.
        </div>
      </v-tooltip>
    </v-col>

    <v-col cols="12" md="8">
      <v-row>
        <v-col cols="12" md="4">
          <v-select :items="types" v-model="type" item-value="id" item-text="label" placeholder="Тип" outlined hide-details dense @change="clearCriteria" />
        </v-col>

        <v-col v-if="type === typesCriteria.STATUS.id" cols="12" md="8" class="d-flex">
          <v-select :items="ivrStatuses" v-model="statusModel" item-value="id" :placeholder="typesCriteria.STATUS.label" item-text="label" outlined hide-details dense />
        </v-col>

        <v-col v-if="type === typesCriteria.MAX_BILL_SEC.id" cols="12" md="8" class="d-flex">
          <v-text-field v-model="maxBillModel" outlined hide-details dense placeholder="20" class="mr-3"/>
          <v-select :items="typesTime" v-model="multiplierTime" item-value="multiplier" item-text="label" outlined hide-details dense />
        </v-col>

        <v-col v-if="type === typesCriteria.DIGIT.id" cols="12" md="8">
          <v-select :items="usedCriteriaDigits" v-model="digitModel" :placeholder="typesCriteria.DIGIT.label" item-value="id" item-text="label" outlined hide-details dense />
        </v-col>

      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { typesCriteria, ivrStatuses } from '../../../../criteria';

export default {
  name: 'CriteriaSuccessCall',
  props: {
    criteria: Object,
    ivrOptions: Object,
  },
  data() {
    return {
      type: null,
      typesCriteria,
      ivrStatuses,

      typesTime: [
        { multiplier: 1, label: 'сек' },
        { multiplier: 60, label: 'мин' },
      ],
      multiplierTime: 1,
    };
  },
  computed: {
    usedCriteriaDigits() {
      // const usedDigits = Object.keys(this.ivrOptions);
      // return [...criteriaDigits.filter(d => usedDigits.includes(d.id)), ...speechDigit.filter(d => usedDigits.includes(d.id))];
      return [...Object.keys(this.ivrOptions)];
    },
    types() {
      return Object.values(typesCriteria);
    },
    statusModel: {
      get() {
        return this.criteria[typesCriteria.STATUS.id];
      },
      set(status) {
        this.setValue(status);
      },
    },
    maxBillModel: {
      get() {
        return (this.criteria[typesCriteria.MAX_BILL_SEC.id] / this.multiplierTime).toFixed(0);
      },
      set(v) {
        this.setValue(v * this.multiplierTime);
      },
    },
    digitModel: {
      get() {
        return this.criteria[typesCriteria.DIGIT.id];
      },
      set(digit) {
        this.setValue(digit);
      },
    },
  },
  created() {
    this.setType();
  },
  watch: {
    criteria() {
      this.setType();
    },
  },
  methods: {
    setType() {
      if (this.criteria[typesCriteria.STATUS.id]) {
        this.type = typesCriteria.STATUS.id;
      } else if (this.criteria[typesCriteria.MAX_BILL_SEC.id]) {
        this.type = typesCriteria.MAX_BILL_SEC.id;
      } else if (this.criteria[typesCriteria.DIGIT.id]) {
        this.type = typesCriteria.DIGIT.id;
      }

      if (this.type === null) {
        this.$emit('update:criteria', { ...this.criteria, [typesCriteria.MAX_BILL_SEC.id]: 10 });
        this.type = typesCriteria.MAX_BILL_SEC.id;
      }
    },
    setValue(value) {
      this.$emit('update:criteria', { ...this.criteria, [this.type]: value });
    },
    clearCriteria() {
      this.$emit('update:criteria', {
        ...this.criteria,
        [typesCriteria.STATUS.id]: null,
        [typesCriteria.MAX_BILL_SEC.id]: 0,
        [typesCriteria.DIGIT.id]: null,
      });
    },
  },
};
</script>
