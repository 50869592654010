<template>
  <div class="mb-12">
    <v-stepper-step :step="1" :complete="currentStep > 1" :editable="currentStep > 1">
      Настройка контактов
    </v-stepper-step>

    <v-stepper-content :step="1">
      <v-tabs grow :value="typeUpload">
        <v-tab @click="$emit('update:typeUpload', types.IS_UPLOAD)">Импорт нового списка</v-tab>
        <v-tab @click="$emit('update:typeUpload', types.IS_SELECT)">Выбрать из ранее загруженных</v-tab>

        <v-tabs-items :value="typeUpload">
          <v-tab-item class="mt-3 pa-2">
            <ImportContacts @after-import="afterImport" />
          </v-tab-item>

          <v-tab-item class="mt-3 pa-2">
            <div style="position: relative">
              <v-btn
                v-show="isVisibleSelectOk"
                small
                absolute
                type="button"
                color="primary"
                dark
                style="top: 10px;right: 25px;z-index: 10;"
              >
                Выбрать
              </v-btn>
              <v-select
                  placeholder="Выберите список"
                  outlined
                  hide-details
                  :items="BASES"
                  return-value
                  item-text="name"
                  item-value="id"
                  multiple
                  :value="usedBases"
                  @input="handleSelectBase"
                  v-click-outside="handlerOutsideClickSelect"
                  @click="isVisibleSelectOk = true"
              />
            </div>
            <v-btn :disabled="!usedBases.length" class="mt-4" dark color="hemp" @click="nextStep">Продолжить</v-btn>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

    </v-stepper-content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import types from './types';
import ImportContacts from '../../../../../components/import-contacts/ImportContacts.vue';

export default {
  name: 'AutocallStep1',
  components: { ImportContacts },
  props: {
    currentStep: Number,
    usedBases: Array,
    typeUpload: {
      type: Number,
      default: types.IS_UPLOAD,
    },
  },
  data() {
    return {
      disabledNextBtn: true,

      types,

      isVisibleSelectOk: false,
    };
  },
  computed: {
    ...mapGetters(['BASES']),
  },
  methods: {
    handlerOutsideClickSelect(e) {
      if (e.target.closest('.v-select-list')) return;

      this.isVisibleSelectOk = false;
    },
    handleSelectBase(bases = []) {
      this.$emit('update:usedBases', bases);
    },
    afterImport(base) {
      this.$emit('update:usedBases', [base.id]);
      this.nextStep();
    },
    nextStep() {
      this.$emit('next');
    },
  },
};
</script>
