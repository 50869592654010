<template>
  <v-row class="mt-1">
    <v-col cols="12" md="4" class="mt-2">
      Оповещение на email
      <v-tooltip right max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
        </template>
        <div class="text-caption">
          На указанный адрес электронной почты будет приходить отчет об окончании рекламной кампании или возникновении ошибки.
        </div>
      </v-tooltip>
    </v-col>
    <v-col cols="12" md="8" class="d-flex align-center ">
      <div class="d-flex align-center justify-center pb-6">
        <v-switch v-model="autocall.isEnableEmailReport" color="primary" hide-details class="ma-0"/>
      </div>
      <v-text-field
          v-model="autocall.email"
          :disabled="autocall.isEnableEmailReport ? false : true"
          class="ma-0 pa-0"
          placeholder="dev@email.ru"
          :rules="[rules.min(5), rules.max(20), rules.email]"
          prepend-inner-icon="mdi-email"
        />
    </v-col>
  </v-row>
</template>

<script>
import { rules } from '../../../../../../formValidation';

export default {
  name: 'PauseByError',
  props: {
    autocall: Object,
    value: String,
  },
  data() {
    return {
      rules: {
        min: rules.min,
        max: rules.max,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Не верный e-mail.';
        },
      },
      isPaused: true,
    };
  },
};
</script>
