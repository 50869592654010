<template>
  <v-row class="align-center">
    <TtsHelper />
    <v-col cols="12">
      <div class="mb-2 ml-3 mb-2 mt-5">
        <v-text-field
          class="font-weight-bold"
          value="Управление синтезом речи"
          success
          readonly
        />
        <v-btn
          v-for="(variable, key) in variablesTTS"
          :key="key"
          class="mr-3"
          outlined
          small
          @click="appendVariable(`${variable}`)"
        >
          {{key}}
        </v-btn>
      </div>
      <div class="mb-2 ml-3 mt-2" v-if="variablesInPhrase.length">
        <v-text-field
          class="font-weight-bold"
          value="Переменные"
          readonly
          success
        />
        <v-btn
          v-for="(variable, key) in variablesInPhrase"
          :key="key"
          class="mr-3 ml-3"
          small
          @click="appendVariable(`%${variable}%`)"
        >
          {{variable}}
        </v-btn>
      </div>
      <v-textarea ref="textarea" class="mt-6 ml-3 mr-4" outlined name="tts-phrase" label="Фраза *" :rules="[rules.required]" v-model="model" />
    </v-col>
    <TtsSettings v-model="model" :rules="rules" :tts="value"/>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { rules } from '../../../../../../../formValidation';
import TtsHelper from './TtsHelper.vue';
import TtsSettings from './TtsSettings.vue';

export default {
  name: 'Tts',
  components: { TtsHelper, TtsSettings },
  props: {
    value: Object,
    bases: Array,
  },
  data() {
    return {
      rules: {
        required: rules.required,
      },
      variablesTTS: {
        Ударение: '+',
        Пауза: ' -  -  -  -  -  -  -  -  -  -  -  - ',
        Акцент: '**',
      },
    };
  },
  computed: {
    ...mapGetters(['BASES']),
    selectedBases() {
      return this.BASES.filter(({ id }) => this.bases.includes(id));
    },
    variablesInPhrase() {
      const allVariables = this.selectedBases.length
        ? this.selectedBases.reduce((list, base) => (
          base.list.length
            ? [...base.list[0].fields.map(f => f.name.trim().toLowerCase()), ...list]
            : list
        ), [])
        : [];
      return Array.from(new Set(allVariables));
    },
    model: {
      get() {
        return this.value.phrase;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    appendVariable(insertValue) {
      const textarea = this.$refs.textarea.$el.querySelector('textarea');

      let cursorPos = textarea.selectionEnd;

      this.$emit('input', this.model.substring(0, cursorPos) + insertValue + this.model.substring(cursorPos));

      cursorPos += insertValue.length;

      this.$nextTick(() => textarea.setSelectionRange(cursorPos, cursorPos));
    },
  },
};
</script>
