<template>
  <v-col cols="12">
    <input-drag-and-drop v-model="file" accept="audio/wav">
      <template v-slot:support-format-text>
        Поддерживаются форматы: .wav
      </template>
      <template v-slot:default-text>
        Перетащите аудиофайл сюда
      </template>
    </input-drag-and-drop>
  </v-col>
</template>

<script>
import InputDragAndDrop from '../../../../../../components/InputDragAndDrop.vue';

export default {
  name: 'AudioUploader',
  components: { InputDragAndDrop },
  props: ['value'],
  computed: {
    file: {
      get() {
        return this.value && this.value?.file && typeof this.value?.file !== 'string'
          ? this.value.file
          : this.value || null;
      },
      set(value) {
        this.$emit('input', { ...this.value, file: value });
      },
    },
  },
};
</script>
