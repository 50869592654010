<template>
  <v-col cols="12">
    <v-row class="mt-1">
      <v-col cols="12" md="4" class="mt-2">
        Куда будет выходить IVR <span class="red--text">*</span>
      </v-col>
      <v-col cols="12" :md="model === ivrTtsExitList.NUMBER.id ? 4 : 8" class="d-flex align-center">
        <v-select :items="arrIvrTtsExitList" v-model="model" outlined hide-details dense item-value="id" item-text="label" label="Выход IVR" />
      </v-col>
      <v-col v-if="model === ivrTtsExitList.NUMBER.id" cols="12" md="4"  class="d-flex align-center">
        <v-select
          :items="targets"
          v-model="exitInternalNumber"
          outlined
          hide-details
          dense
          label="Выход IVR *"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'IvrExit',
  props: ['value'],
  computed: {
    ...mapState({
      ivrTtsExitList: state => state.ivrTts.ivrTtsExitList,
      ivrTargets: state => state.ivrSettings.ivrTargets,
    }),
    arrIvrTtsExitList() {
      return Object.values(this.ivrTtsExitList);
    },
    // targets() {
    //   return this.ivrTargets.reduce((all, cur) => ([...all, { header: cur.name }, ...cur.list]), []);
    // },
    targets() {
      return this.ivrTargets.reduce((total, group) => ([
        ...total,
        { header: group.name },
        ...group.list.map(({ name, number }) => ({ value: +number, text: `${number} - ${name}` })),
      ]), []);
    },
    model: {
      get() {
        const exit = this.arrIvrTtsExitList.find(({ name }) => name === this.value);

        return exit ? exit.id : this.ivrTtsExitList.NUMBER.id;
      },
      set(value) {
        const exit = this.arrIvrTtsExitList.find(({ id }) => id === value);

        if (exit) {
          this.$emit('input', exit.name);
        }
      },
    },
    exitInternalNumber: {
      get() {
        // return !this.arrIvrTtsExitList.find(({ name }) => name === this.value) ? this.value : null;
        return this.value;
      },
      set(value) {
        if (!this.arrIvrTtsExitList.find(({ id }) => id === value)) {
          this.$emit('input', value);
        }
      },
    },
  },
};
</script>
