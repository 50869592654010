<template>
  <v-row>
    <v-col cols="12" md="4" class="mt-2">
      Прервать кампанию в указанные даты
      <v-tooltip right max-width="450px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
        </template>
        <div class="text-caption">
          В указанный период кампания будет автоматически остановлена. Например, вы можете исключить праздничные или выходные дни из рассылки сообщений.
        </div>
      </v-tooltip>
    </v-col>
    <v-col cols="12" md="8" class="d-flex">
      <v-dialog
        ref="daysDialog"
        v-model="daysDialog"
        :return-value.sync="model"
        persistent
        width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-combobox v-model="model" outlined dense hide-details label="Даты" multiple chips small-chips readonly v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar">
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                small
                class="ma-1"
                @click="data.select"
                @click:close="removeSelected(data.item)"
              >
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </template>
        <v-date-picker v-model="model" :min="nowDate" multiple no-title scrollable width="300">
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="daysDialog = false">Отмена</v-btn>
          <v-btn text color="primary" @click="$refs.daysDialog.save(model)">Выбрать</v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ExceptionsDates',
  props: {
    value: Object,
  },
  data() {
    return {
      daysDialog: false,
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    model: {
      get() {
        return this.value?.exceptions ? this.value.exceptions.split(', ') : [];
      },
      set(exceptions) {
        this.$emit('input', { ...this.value, exceptions: exceptions.join(', ') });
      },
    },
  },
  methods: {
    removeSelected(item) {
      this.$emit('input', {
        ...this.value,
        exceptions: this.model.filter(date => date !== item).join(', '),
      });
    },
  },
};
</script>
